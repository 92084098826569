const filter = "?files=1";

export default [
    {
        name: "CDN ADMIN BE",
        created_at: "2019-01-06T20:36:58Z",
        language: "JavaScript",
        url: "https://github.com/alonzo245/uploads_api" + filter,
        description: "Backend Admin for managing CDN files built with NodeJS, Express and JWT.",
        technologies: ["javascript", "nodejs", "jwt", "webpack", "expressjs", "mongodb"],
    },
    {
        name: "CDN ADMIN FE",
        created_at: "2019-01-07T17:41:33Z",
        language: "JavaScript",
        url: "https://github.com/alonzo245/uploads_app" + filter,
        description: "Frontend ReactJS Admin for managing CDN files with login auth system.",
        technologies: ["javascript", "reactjs", "redux", "html5", "css3", "sass", "jwt"],
    },
    {
        name: "Gatsby + Firebase ",
        created_at: "2019-07-28T11:17:54Z",
        url: "https://github.com/alonzo245/gatsby-db" + filter,
        description: "Amazing and easy way to connect to firestore and generating blog pages.",
        technologies: ["nodejs", "javascript", "gatsby", "graphql", "sass", "html5", "css3"],
    },
    {
        name: "Gatsby Static Blog",
        created_at: "2019-06-26T19:35:51Z",
        url: "https://github.com/alonzo245/gatsby-static" + filter,
        description: "Great blog made with SSG tech.",
        technologies: ["nodejs", "javascript", "gatsby", "graphql", "sass", "html5", "css3"],
    },
    {
        name: "Gatsby + Wordpress CMS",
        created_at: "2019-06-23T20:29:27Z",
        url: "https://github.com/alonzo245/gatsby-wordpress" + filter,
        description: "Using Wordpress CMS for managing static Site with GatsbyJS.",
        technologies: ["nodejs", "javascript", "gatsby", "graphql", "sass", "html5", "css3"],
    },
    {
        name: "ReactJS Spring Animation",
        created_at: "2019-09-10T07:04:48Z",
        language: "JavaScript",
        url: "https://github.com/alonzo245/react-spring-animation" + filter,
        description: "Demonstrate animations with react.",
        technologies: ["javascript", "reactjs", "springAmin", "html5", "css3"],
    },
    {
        name: "Simon Says JS Vanilla",
        created_at: "2019-09-10T07:04:48Z",
        language: "JavaScript",
        url: "https://github.com/alonzo245/simon-says-js-vanilla" + filter,
        description:
            "Fun Demo for playing the addictive memory game made with pure Javascript CSS and HTML5.",
        technologies: ["javascript", "html5", "css3"],
    },
    {
        name: "ReactJS Pagination",
        created_at: "2019-09-08T15:56:40Z",
        language: "JavaScript",
        url: "https://github.com/alonzo245/reactjs-pagination" + filter,
        description:
            "Handy and quick component to implement pagination in your ReactJS app.App-link",
        technologies: ["javascript", "reactjs", "html5", "css3"],
    },
    {
        name: "TODO App in ReactJS TypeScript Styled",
        created_at: "2019-09-13T18:39:25Z",
        language: "TypeScript",
        url: "https://github.com/alonzo245/reactjs-ts-styled" + filter,
        description: "Just another TODO App using latest web most popular libraries",
        technologies: ["javascript", "styled", "typescript", "lodash"],
    },
    {
        name: "ExpressJS TypeScript Boilerplate",
        created_at: "2019-09-12T05:01:38Z",
        url: "https://github.com/alonzo245/express-typescript-template" + filter,
        description: "Great for spinning quick APIs.",
        technologies: ["javascript", "nodejs", "typescript", "expressjs"],
    },
    {
        name: "React Native Guess a Number App",
        created_at: "2019-08-17T11:24:43Z",
        url: "https://github.com/alonzo245/guess-number" + filter,
        description: "Fun to Play App, can you guess a number...",
        technologies: ["javascript", "android", "reactnative"],
    },
    {
        name: "NndeJS Express ES6 Auto Reload",
        created_at: "2019-09-11T11:31:26Z",
        url: "https://github.com/alonzo245/nodejs-express-es6-auto-reload" + filter,
        description: "BoilerPlate for spinning backend web projects with quicl auto save function.",
        technologies: ["javascript", "nodejs", "expressjs"],
    },
    {
        name: "NndeJS Express JWT Api",
        created_at: "2019-09-07T17:00:42Z",
        url: "https://github.com/alonzo245/nodejs-express-jwt-api" + filter,
        description: "Api set with authentication.",
        technologies: ["javascript", "nodejs", "expressjs", "jwt"],
    },
    {
        name: "NodeJS Express Redis Boilerplate",
        created_at: "2019-09-07T17:49:09Z",
        url: "https://github.com/alonzo245/nodejs-express-redis" + filter,
        description: "Just another boilerplate for web apps.",
        technologies: ["javascript", "expressjs", "nodejs", "redis"],
    },
    {
        name: "NodeJS + PUG web views",
        created_at: "2019-09-07T16:44:28Z",
        url: "https://github.com/alonzo245/nodejs-pug-views" + filter,
        description: "Writing web views yousung pug library.",
        technologies: ["javascript", "nodejs", "pug", "expressjs"],
    },
    {
        name: "Sessions Managment in ExpressJS",
        created_at: "2019-09-07T16:55:23Z",
        url: "https://github.com/alonzo245/nodejs-sessions-and-cookies" + filter,
        description: "User login system using the abbilities of ExpressJS wih cookies",
        technologies: ["javascript", "nodejs", "expressjs"],
    },
    {
        name: "Short Url App using nodeJs",
        created_at: "2019-09-08T05:50:24Z",
        url: "https://github.com/alonzo245/nodejs-shor-url-app" + filter,
        description: "Convert long url to short and simple using this app.",
        technologies: ["javascript", "nodejs", "expressjs"],
    },
    {
        name: "NodeJS + EJS web views",
        created_at: "2019-09-07T16:47:09Z",
        url: "https://github.com/alonzo245/nodejs-views-ejs" + filter,
        description: "Writing web views yousung EJS library.",
        technologies: ["javascript", "nodejs", "ejs", "expressjs"],
    },
    {
        name: "NodeJS + ReactJS CDN Storage Managment",
        created_at: "2019-01-13T21:32:31Z",
        url: "https://github.com/alonzo245/nodejs_reactjs_storage_management" + filter,
        description: "E2E Web Application for storing files in a cloud.",
        technologies: [
            "javascript",
            "nodejs",
            "expressjs",
            "jwt",
            "sass",
            "html5",
            "css3",
            "reactjs",
            "redux",
        ],
    },
    {
        name: "Calculator App",
        created_at: "2019-07-11T22:05:16Z",
        url: "https://github.com/alonzo245/android-calc" + filter,
        description: "Just another calculator written with Kotlin for Android Os.",
        technologies: ["kotlin", "android"],
    },
    {
        name: "Angular Admin Dashboard",
        created_at: "2019-04-06T12:36:44Z",
        url: "https://github.com/alonzo245/angular-dashboard" + filter,
        description:
            "Demo of graphical Admin with charts and progress bars fully responsive made with Angular 8.0",
        technologies: ["javascript", "angular", "html5", "sass", "css3"],
    },
    {
        name: "Python + Django Website",
        created_at: "2019-09-05T04:35:48Z",
        url: "https://github.com/alonzo245/django-python" + filter,
        description:
            "Easy peasy Poll admin and web app demonstrate Django abillities with the web.",
        technologies: ["python", "django", "html5", "css3"],
    },
    {
        name: "NodeJS YouFlix App",
        created_at: "2018-12-14T12:11:45Z",
        language: "JavaScript",
        url: "https://github.com/alonzo245/nodejs_youflix_app" + filter,
        description:
            "Frontend ReactJS application VOD for YouTube Videos, with responsive design, Login system and User posts area.",
        technologies: ["javascript"],
    },
    {
        name: "Python Flask Website",
        created_at: "2019-08-31T19:25:29Z",
        language: "Python",
        url: "https://github.com/alonzo245/python-flask" + filter,
        description: "Simple Website made with Python and Flask framework",
        technologies: ["python", "flask"],
    },
    {
        name: "React Native Goals",
        created_at: "2019-08-17T07:14:40Z",
        language: "JavaScript",
        url: "https://github.com/alonzo245/react-native-goals" + filter,
        description: "Manage your life goals in an Android app.",
        technologies: ["android", "reactnative"],
    },
    {
        name: "Piano in ReactJS",
        created_at: "2019-01-02T19:37:14Z",
        language: "JavaScript",
        url: "https://github.com/alonzo245/reactjs_piano" + filter,
        description: "Simple web Piano made with ReactJS.",
        technologies: ["javascript", "reactjs", "html5", "css3"],
    },
    {
        name: "ReactJS Widget Slider Plugin",
        created_at: "2019-01-03T19:53:31Z",
        language: "JavaScript",
        url: "https://github.com/alonzo245/reactjs_slider" + filter,
        description:
            "Injectible ReactJS widget include Redux. HTML5, CSS3 responsive media queiries.",
        technologies: ["javascript", "reactjs", "redux", "html5", "css3", "sass"],
    },
    {
        name: "ReactJS Widget Carousel Plugin",
        created_at: "2019-01-05T19:13:16Z",
        language: "JavaScript",
        url: "https://github.com/alonzo245/reactjs_carousel" + filter,
        description:
            "Smart injectable to any website Carousel widget, with data based from dedicated API ",
        technologies: ["javascript", "reactjs", "html5", "css3"],
    },
    {
        name: "YouFlix VOD API",
        created_at: "2018-12-18T06:02:43Z",
        language: "JavaScript",
        url: "https://github.com/alonzo245/reactjs_youflix_api" + filter,
        demoUrl: "https://github.com/alonzo245/reactjs_youflix_api",
        description:
            "Backend API written in NodeJS, Express, MongoDb, JWT, Multer. including uploading files.",
        technologies: [
            "javascript",
            "nodejs",
            "jwt",
            "webpack",
            "expressjs",
            "mongodb",
            "mongoose",
        ],
    },
    {
        name: "Pure CSS3 HTML5 Social Website",
        created_at: "2019-09-14T15:42:56Z",
        language: "CSS",
        url: "https://github.com/alonzo245/sass-html5-demo-website" + filter,
        description:
            "Website contain full responsive design, Login, Registration, Admin and inner actoin pages.",
        technologies: ["sass", "css3", "html5"],
    },
];
