import investing from "./investing.png";
import jolt from "./jolt.png";
import s from "./s.jpeg";
import pepper from "./pepper.png";
import coderz from "./coderz.png";
import ynet from "./ynet.png";
import ziprecruiter from "./ziprecruiter.png";

export default {
    jolt: jolt,
    pepper: pepper,
    investing: investing,
    coderz: coderz,
    ynet: ynet,
    s: s,
    ziprecruiter: ziprecruiter,
};
