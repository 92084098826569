import React from "react";

export default [
    {
        title: "Summary",
        bullets: [
            "In charge of the R&D roadmap and technical direction, guidance and quality.",
            "Leading and managing infrastructure of technological plans, strategies, methodologies and budgeting.",
            "Hands on developer for more than 15 years.",
            "Passionate on code and mentoring junior developers in the tech industry.",
            "Agile in learning new ranges of technologies.",
            "Experience with public cloud environments",
            "Strive for product’s goals as defined and documented, in the scheduled commitments.",
            "Researching ways to improve new and existing technologies of the company.",
            "Recruit groom and mentor junior and senior talented developers.",
            "Experience in Microservices architecture and SaaS environments.",
            "Excellent communication and problem-solving skills.",
        ],
    },
    {
        title: "Hands On Coding Experience",
        bullets: [
            "Backend: NodeJS, Typescript, NestJS, ExpressJS, GraphQL, Apollo Server, Apollo Federation, Apollo Engine, Microservices Architecture, HTTP Protocols, Experienced with BASH/ZSH.",
            "Frontend: React, Apollo Client GraphQL, Emotion Styled for CSS, Gatsby, Deep understanding of DOM and Shadow DOM.",
            "Databases: MongoDB, Mysql, Cassandra, Mongo Atlas, ElasticSearch, FireStore, SQLite, Redis, memcache.",
            ",Build Tools: NPM Packaging and publishing, Webpack.",
            ",React Native: Expo Framework.",
            ",Frontend UI: Ant Design, Material UI.",
            "Dev Methodologies: SOLID and Clean Code Architecture TDD writing tests first.",
            "Testing Frameworks: Jest, Mocha / Jasmine, Puppeteer, SuperTest, Spy, SuperMock",
            "Cloud / Devops: AWS, EKS, Helm, Buddy, RDS, Route 53, EC2, Kebernates, pods, services and deployments, NGINX, Kibana, also experienced wit Firebase and GCloud.",
            "Agile practices: Experienced as a Scrum Master, Agile Scrum, Kanban.",
            "Version Control: Git, Github, GitLab, BitBucket, Azure VSTS, TFS.",
            "IC/CD: CircleCi, Jenkins, Buddy Works, Nolio, Zabbix.",
        ],
    },
];
